<template>
  <el-dialog :visible="visible" title="编辑课程" @close="close" :close-on-click-modal="false" class="yt-dialog yt-dialog-default">
    <el-form ref="editForm" :model="editForm" :rules="editValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="课程名称" prop="name">
        <el-input v-model="editForm.name" placeholder="请输入名称" />
        <div v-if="editValidate.name.error !== true" class="form-tip">
          不超过 80 个字
        </div>
      </el-form-item>
      <el-form-item label="课程封面" prop="cover">
        <Upload
          :headers="coverType === 0 ? {} : token"
          ref="upload"
          :show-upload-list="false"
          :data="uploadParams"
          :on-success="uploadCoverSuccess"
          :before-upload="beforeUpload"
          :on-progress="UploadImg"
          :on-exceeded-size="handleExceededSize"
          :format="['jpg', 'jpeg', 'png']"
          :max-size="10240"
          type="drag"
          :action="uploadUrl"
        >
          <Progress v-if="isUploading" :percent="percentage" hide-info />
          <img :src="editForm.cover" alt="" />
        </Upload>
        <div class="form-tip" v-if="editValidate.cover.error !== true">
          支持 jpg/gif/png 格式，不超过 10M
        </div>
      </el-form-item>
      <!--      <FormItem label="选择岗位">-->
      <!--        <el-cascader ref="cascader" style="width: 100%" size="small" :show-all-levels="false" :options="postData"-->
      <!--                     v-model="editForm.postIds" :props="{ expandTrigger: 'hover', multiple: true, checkStrictly: true }"-->
      <!--                     filterable clearable/>-->
      <!--      </FormItem>-->
      <!--      <FormItem label="公开课程" prop="open">-->
      <!--        <RadioGroup v-model="editForm.open">-->
      <!--          <Radio :label="0">是</Radio>-->
      <!--          <Radio :label="1">否</Radio>-->
      <!--        </RadioGroup>-->
      <!--      </FormItem>-->
      <el-form-item label="课程简介" prop="introduction">
        <el-input type="textarea" placeholder="请输入课程简介" v-model="editForm.introduction" />
        <div v-if="editValidate.introduction.error !== true" class="form-tip">
          不超过 600 个字
        </div>
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button @click="close" class="button">取消</el-button>
      <el-button type="primary" @click="editCourse" class="button" :loading="loading">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import courseApi from '@api/course'
import ossApi from '@api/oss'
import DateTimePicker from '../../../common/DateTimePicker'
import AddTeacher from '../../../common/AddTeacher'
import functionApi from '@api/functionApi'
import config from '../../../../config/config'

export default {
  name: 'EditModal',
  components: {
    DateTimePicker,
    AddTeacher
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    const that = this
    return {
      token: {
        Authorization: 'Bearer ' + (localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')).access_token)
      },
      coverType: '',
      serveLoca: '',
      loading: false,
      postData: [],
      visible: this.value,
      uploadUrl: config.baseUrl + '/file/api/v1/upload/cover',
      isUploading: false,
      percentage: 0,
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      editId: this.form.courseId,
      editForm: {
        name: this.form.name,
        cover: this.form.cover,
        introduction: this.form.description,
        open: this.form.publicity,
        postIds: [],
        category: '1',
        chapters: [],
        status: 1,
        students: []
      },
      editValidate: {
        // postIds: {
        //   required: true,
        //   validator: (rule, value, callback) => {
        //     if (value.length === 0) {
        //       callback(newPaper Error('请选择岗位'))
        //     } else {
        //       callback()
        //     }
        //   }
        // },
        name: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.length === 0 || value.split(' ').join('').length === 0) {
              that.editValidate.name.error = true
              callback(new Error('课程名称不为空'))
            } else if (value.length > 80) {
              that.editValidate.name.error = true
              callback(new Error('名称不超过 80 字'))
            } else {
              callback()
            }
          }
        },
        cover: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.length === 0) {
              that.editValidate.cover.error = true
              callback(new Error('请选择课程封面'))
            } else {
              callback()
            }
          }
        },
        open: {
          required: true,
          message: '请选择是否公开'
        },
        introduction: [{ max: 600, message: '简介长度不能超过600个字符' }],
        startTime: [
          {
            required: true,
            type: 'date',
            message: '请选择开始时间',
            trigger: 'blur'
          }
        ],
        endTime: [
          {
            required: true,
            type: 'date',
            message: '请选择结束时间',
            trigger: 'blur'
          }
        ],
        teacher: [
          {
            required: true,
            message: '请选择课程讲师'
          }
        ]
      }
    }
  },
  created() {
    functionApi.getAllPostDataById(0).then(res => {
      let arr = JSON.stringify(res.res)
      this.postData = JSON.parse(
        arr
          .replace(/posts/g, 'children')
          .replace(/id/g, 'value')
          .replace(/name/g, 'label')
      )
    })
    ossApi.upType().then(type => {
      this.coverType = type.res
    })
  },
  methods: {
    beforeUpload(file) {
      let type = file.name.slice(file.name.lastIndexOf('.'))
      let fileName = file.name.slice(0, -4)
      fileName = fileName.replace(/[ |~`!@#$%^&*()\-_+=\\\[\]{};:"',<.>\/?，。；：“”》《、]/g, '') + type
      let newFile = new File([file], fileName, { type: type })
      if (window.uploadUrl) {
        let formData = new FormData()
        formData.append('file', newFile)
        ossApi.upCover(formData).then(res => {
          this.serveLoca = res.data.res
          this.uploadCoverSuccess()
        })
      } else {
        const payload = {
          courseId: new Date().valueOf(),
          name: newFile.name
        }
        // if (this.coverType === 0) {
        ossApi.getCourseCoverToken(payload).then(res => {
          const data = res.res
          this.uploadUrl = `https://${data.host}`
          this.uploadParams.key = data.dir
          this.uploadParams.OSSAccessKeyId = data.accessId
          this.uploadParams.policy = data.policy
          this.uploadParams.Signature = data.signature
          this.isUploading = true
          let formData = new FormData()
          formData.append('key', this.uploadParams.key)
          formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
          formData.append('policy', this.uploadParams.policy)
          formData.append('Signature', this.uploadParams.Signature)
          formData.append('file', newFile)
          fetch(`https://${data.host}`, {
            method: 'POST',
            body: formData
          }).then(() => {
            this.uploadCoverSuccess()
          })
        })
        // }
      }
      return false
    },
    UploadImg(event, file) {
      this.percentage = file.percentage
    },
    uploadCoverSuccess(res) {
      if (window.uploadUrl) {
        this.editForm.cover = this.serveLoca
      } else {
        this.editForm.cover = `${this.uploadUrl}/${this.uploadParams.key}`
      }
      this.isUploading = false
    },
    handleExceededSize() {
      this.$message.error('图片大小不超过 10M')
    },
    editCourse() {
      this.$refs['editForm'].validate(valid => {
        if (valid) {
          // let arr = this.$refs.cascader.getCheckedNodes().map(item => {
          //   return item.value
          // })
          const payload = {
            courseId: this.editId,
            cover: this.editForm.cover,
            description: this.editForm.introduction,
            name: this.editForm.name,
            publicity: this.editForm.open,
            // postIds: arr,
            category: '1',
            chapters: [],
            status: 1,
            students: []
          }
          this.$refs['editForm'].validate(valid => {
            if (!valid) {
              this.$message.error('请填写必填项!')
            } else if (this.editForm.endTime <= this.editForm.startTime) {
              this.$message.error('开始时间晚于结束时间,请重新选择时间')
            } else if (this.editForm.startTime < Date.now()) {
              this.$message.error('已过开始时间,请重新选择时间')
            } else {
              this.loading = true
              courseApi
                .editCourse(payload)
                .then(res => {
                  if (res) {
                    this.loading = false
                    this.$message.success('编辑成功')
                    this.$emit('on-reflash-list')
                    this.close()
                  } else {
                    this.loading = false
                  }
                })
                .catch(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    },
    close() {
      this.$emit('input', false)
      this.visible = false
    }
  },
  watch: {
    value(val) {
      this.visible = val
    },
    form(val) {
      this.editId = val.courseId
      this.editForm.name = val.name
      this.editForm.cover = val.cover
      this.editForm.introduction = val.description
      this.editForm.open = val.publicity
      // this.editForm.postIds = val.posts.map(item => item.id)
    }
  }
}
</script>

<style lang="less" scoped>
div ::v-deep .ivu-upload {
  width: 120px;
  height: 120px;
  img {
    width: 120px;
    height: 120px;
  }
}
::v-deep .yt-dialog-form {
  text-align: left;
}
.upload {
  width: 124px;
  height: 90px;
  font-size: 60px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #b8c2d1;
  }
}
</style>
